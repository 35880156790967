
import { defineComponent } from 'vue';
import { toast } from '../main';
import spinner from '@/components/spinner.vue';

export default defineComponent({
	data(): any {
		return {
			apps: [],
			isLoader: false,
			usersArray: [],
			userData: [],
			users: [],
			editOption: true,
			organisations: [],
			roles: [
				{
					roleName: 'Monitor Root Admin',
					roleId: 10201,
					moduleId: 10001
				},
				{
					roleName: 'Admin',
					roleId: 10202,
					moduleId: 10001
				},
				{
					roleName: 'Department Owner',
					roleId: 10203,
					moduleId: 10001
				},
				{
					roleName: 'Task Owner',
					roleId: 10204,
					moduleId: 10001
				},
				{
					roleName: 'User',
					roleId: 10205,
					moduleId: 10001
				},
				{
					roleName: 'Training Root Admin',
					roleId: 10206,
					moduleId: 10002,
					disabled: true
				},
				{
					roleName: 'Client Admin',
					roleId: 10207,
					moduleId: 10002
				},
				{
					roleName: 'User',
					roleId: 10208,
					moduleId: 10002
				}
			],
			selectedUser: {}
		};
	},
	computed: {},
	methods: {
		selectUser(event: any, user: any) {
			this.selectedUser = this.userData.find((user: any) => user.name == event.target.value);
			this.selectedUser.roles.forEach((role: any, index: any) => {
				this.roles.forEach((roles: any) => {
					if (role.appId == roles.moduleId && role.appId == 10001) {
						this.selectedUser.roles[index].roleId = role.roleId;
						user.selectMonitor = role.roleId;
					} else {
						this.selectedUser.roles[index].roleId = role.roleId;
						user.selectTraining = role.roleId;
					}
				});
			});
		},
		getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_API_PATH}/tenants/getAll`).then((res: any) => {
				this.organisations = res.data;
				this.organisations.forEach((element: any) => {
					element.isToggle = false;
				});
				this.apps = this.organisations[0].apps;
			});
		},
		clearUser(userIndex: any) {
			this.usersArray.splice(userIndex, 1);
		},
		editUser(userIndex: any) {
			this.usersArray[userIndex].editOption = false;
			this.usersArray[userIndex].showSave = true;
		},
			clearAllFlags(userIndex: any) {
			this.usersArray.splice(userIndex, 1);
		},
		addUser() {
			this.usersArray.push({ showSave: true, selectMonitor: '', selectTraining: '', selecteduserName: '' });
		},
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/users/getAll`).then((res: any) => {
				this.userData = res.data;
			});
			let monitor: any, training: any, userName:any;

			this.userData.forEach((user: any) => {
				user.roles.find((role: any) => {
					if (role.roleId == 10206 || role.roleId == 10201) {
						this.users.push(user);
					}
				});
			});
			this.userData = [...new Set(this.users)];

			this.userData.forEach((user: any, index: any) => {
				user.roles.find((role: any) => {
					if (role.appId == 10001) {
						monitor = role.roleId;
					} else {
						training= role.roleId;
					}
				});
				userName = user.name;
				this.usersArray.push({ showSave: false, selectMonitor: monitor, selectTraining: training, selecteduserName: userName, editOption: true });
			});
		},
		async onSubmit(user: any, organisation: any, userName: any,userIndex:any) {
			this.usersArray[userIndex].editOption = true
			this.usersArray[userIndex].showSave = false;
			this.selectedUser = this.userData.find((user: any) => user.name == userName);
			this.selectedUser.roles.forEach((role: any, index: any) => {
				if (role.appId == 10001) {
					this.selectedUser.roles[index].roleId = user.selectMonitor;
				} else {
					this.selectedUser.roles[index].roleId = user.selectTraining;
				}
				// organisation.isToggle = false;
				
			});
			if (this.selectedUser._id) {
				await this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/users/update`, this.selectedUser).then(() => {
					this.showUserModal = false;
				});
			}
			
		}
	},

	components: {
		spinner,
	},
	async mounted() {
		await this.getAllTenants();
		await this.getAllUsers();
	}
});
